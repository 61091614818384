import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Link } from 'gatsby'

const SiteNavigation = ({ body }) => {
    return body.map((bodyContent, i) => {
		const res = (() => {
			switch (bodyContent.link_type) {
				case 'Text':
					return (
						<Link
							key={i}
							title={bodyContent.link_text}
							to={`/${bodyContent.link_url._meta.uid}`} 
						>
							{bodyContent.link_text}
						</Link>
					)
				case 'Icon':
					return (
						<a title={bodyContent.link_text} href={bodyContent.link_url.url} target="_blank" rel="noopener noreferrer" key={i}>
							<img className="icon" src={bodyContent.link_icon.url} alt={bodyContent.link_text} />
						</a>
					)
				default:
					return
			}
		})()
		return res
    })
}

const Header = ({ siteTitle, siteNav }) => (
	<header>
		<div className="c-header__col c-header__logo">
			<Link to="/" title={RichText.asText(siteTitle)}>
				{RichText.asText(siteTitle)}
			</Link>
		</div>
		<div className="c-header__col c-header__nav">
			<SiteNavigation body={siteNav} />
		</div>
	</header>
)

export default Header

