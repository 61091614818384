import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './elements/header'
import Footer from './elements/footer'
import '../assets/css/index.scss'

const Layout = ({ children }) => (
	
	<StaticQuery
		query={`${Settings}`}
		render={(data) => {
			const bodyData = data.prismic.allSettingss.edges[0].node
			return (
				<div className="c-container">
					<Header siteTitle={bodyData.site_title} siteNav={bodyData.navigation} />
					<main>{children}</main>
					<Footer leftContent={bodyData.footer_leftcolumn} rightContent={bodyData.footer_navigation} />
				</div>
			)
		}}
	/>

)

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout

const Settings = graphql`
{
	prismic {
		allSettingss {
			edges {
				node {
					site_title
					navigation {
						link_icon
						link_text
						link_type
						link_url {
						  	... on PRISMIC__ExternalLink {
								url
							}
							... on PRISMIC_Page {
								_meta {
									uid
								}
							}
						}
					}
					footer_leftcolumn
					footer_navigation {
						link_text
						link_url {
							... on PRISMIC_Page {
								_meta {
									uid
								}
							}
						}
					}
				}
			}
		}
	}
}`