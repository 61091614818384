import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import JetBrainsMonoRegular from "../assets/css/settings/fonts/JetBrainsMono/JetBrainsMono-Regular.woff2"
import JetBrainsMonoItalic from "../assets/css/settings/fonts/JetBrainsMono/JetBrainsMono-Italic.woff2"
import JetBrainsMonoExtraBoldItalic from "../assets/css/settings/fonts/JetBrainsMono/JetBrainsMono-ExtraBold-Italic.woff2"

function SEO({ description, image, lang, meta, title }) {

	const defaultMeta = graphql`{
		prismic {
			allSettingss {
				edges {
					node {
						site_title
						meta_description
						meta_image
					}
				}
			}
		}
	}`

	return (
	<StaticQuery
		query={`${defaultMeta}`}
		render={(data) => {
			const metaData = data.prismic.allSettingss.edges[0].node
			const metaDescription = description || metaData.meta_description[0].text
			const metaTitle = title || metaData.site_title[0].text
			
			let metaImage
			if(image == null) {
				metaImage = `${metaData.meta_image.url}&mark64=aHR0cHM6Ly9zYW5kYm94LXVwbG9hZHMuaW1naXgubmV0L3UvMTU4OTAyNzY2Ny00MDBkOTMwZTAzZGY5MzY2ZjExNzEwNTNiMDI4OTY2ND93PTk2MA&mark-x=16&mark-y=16&mark-w=160`
			} else {
				metaImage = `${image.url}&mark64=aHR0cHM6Ly9zYW5kYm94LXVwbG9hZHMuaW1naXgubmV0L3UvMTU4OTAyNzY2Ny00MDBkOTMwZTAzZGY5MzY2ZjExNzEwNTNiMDI4OTY2ND93PTk2MA&mark-x=16&mark-y=16&mark-w=160`
			}

			return (
				<Helmet
					htmlAttributes={{
						lang,
					}}
					title={metaTitle}
					titleTemplate={`%s | ${metaData.site_title[0].text}`}
					meta={[
						{
							name: `description`,
							content: metaDescription,
						},
						{
							property: `og:title`,
							content: metaTitle,
						},
						{
							property: `og:description`,
							content: metaDescription,
						},
						{
							property: `og:type`,
							content: `website`,
						},
						{
							name: `twitter:card`,
							content: `summary`,
						},
						{
							name: `twitter:title`,
							content: metaTitle,
						},
						{
							name: `twitter:description`,
							content: metaDescription,
						},
						{
							name: `og:image`,
							content: metaImage,
						},
					].concat(meta)}>
						<link rel="preload"
        					  as="font"
							  href={JetBrainsMonoRegular}
							  type="font/woff2"
							  crossOrigin="anonymous" />
						<link rel="preload"
							  as="font"
							  href={JetBrainsMonoItalic}
							  type="font/woff2"
							  crossOrigin="anonymous" />
						<link rel="preload"
							  as="font"
							  href={JetBrainsMonoExtraBoldItalic}
							  type="font/woff2"
							  crossOrigin="anonymous" />
					</Helmet>
				)	
			}}	
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO