import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'
import { Link } from 'gatsby'

const FooterNavigation = ({ body }) => {
    return body.map((bodyContent, i) => (
		<Link
			key={i}
			className="c-footer__navlink"
			title={bodyContent.link_text}
			to={`/${bodyContent.link_url._meta.uid}`} 
		>
			{bodyContent.link_text}
		</Link>
	))
}

const Footer = ({ leftContent, rightContent }) => (
	<footer>
		<div className="c-footer__col">
			{RichText.render(leftContent, linkResolver)}
		</div>
		<div className="c-footer__col margin-left">
			<FooterNavigation body={rightContent} />
		</div>
	</footer>
)

export default Footer
